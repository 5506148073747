// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_selected__FF-8I{\n    transform: scale(1.09);\n    transition: all 1s;\n    \n}\n.style_selected__FF-8I:hover{\n    \n}", "",{"version":3,"sources":["webpack://./src/components/PlanContainer/style.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kBAAkB;;AAEtB;AACA;;AAEA","sourcesContent":[".selected{\n    transform: scale(1.09);\n    transition: all 1s;\n    \n}\n.selected:hover{\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selected": "style_selected__FF-8I"
};
export default ___CSS_LOADER_EXPORT___;
