
import style from './style.module.css'
import { Col, Row, Card, Form, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Simulator from '../Simulator';
import Lapiz from "../../utils/img/lapiz.png";
import { ReactComponent as Circulo } from "../../utils/img/circuloVerde.svg";
import axios from 'axios';
import React,{useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {URL} from '../../actions/constants';
import { useNavigate } from "react-router-dom";
import Toast from 'react-bootstrap/Toast';
function Container() {
  const navigate = useNavigate();
  let [form,setForm] = useState({
    username:'',
    lastName:'', 
    mothersLastName:'', 
    birthPlace:'',
    relationship:'', 
    email:'', 
    phone:'',
    dateOfBirth:'',
    objetive:'',
    objetiveKnown:'', 
    amountpermonth:0
  })
  const [isUp, setIsUp] = useState({
    RFC:false,
    INE:false,
    ACTA:false
  })
  const [isAuth,setAuth] = useState(sessionStorage.getItem("isAuth"))
  const [show, setShow] = useState(false)
  const [isChild, setIsChild] = useState(false)
  const [isKnown, setIsKnown] = useState(false)
  const [isCont, setIsCont] = useState(true)
  const [files, setFiles] = useState([])
  const userId = useSelector(state => state.userId);
  const haveDocuments = useSelector(state=> state.haveDocuments)
  const cleanForm = ()=>{
    setForm({
      username:'',
    lastName:'', 
    mothersLastName:'', 
    birthPlace:'',
    relationship:'', 
    email:'', 
    phone:'',
    dateOfBirth:'',
    objetive:'',
    objetiveKnown:'', 
    amountpermonth:''
      });
      setIsUp({
        RFC:false,
        INE:false,
        ACTA:false
        })
        setFiles([])
  }
  const handleChange = (e) =>{
    e.preventDefault()
    console.log(e.target.id)
    console.log(e.target.value)
  if(e.target.id == "docs"||e.target.id == "INE"||e.target.id == "RFC"||e.target.id == "ACTA"){
    let aux = isUp;
    aux[e.target.id] = true;
    setIsUp({...aux});
   files.push(e.target.files[0])
  }else{
  let formAux = form
  formAux[e.target.id] = e.target.value;
  setForm({
    ...formAux,
  })
}
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (files.length >=2) {
    let formI = new FormData();
    formI.append('files',files[0])
    formI.append('files',files[1])
    formI.append('files',files[2])
    axios.post(`${URL}client/save-docs/${userId}`,formI)
    .then(res=>{
      if(res.data.status == 200){
        axios.post(`${URL}client/save-contributor/${userId}`,form)
    .then(res=>{
      if(res.data.status == 200){
        cleanForm()
      }
    });
      }
     
    });
  }
  console.log(files.length)
  if(files.length < 2){
    const formData = new FormData();
    formData.enctype = "multipart/form-data";
    formData.append('acta', files[0]);
    formData.append('name',form.username);
    formData.append('mothersLastName',form.mothersLastName);
    formData.append('lastName',form.lastName);
    formData.append('dateOfBirth',form.dateOfBirth);
    formData.append('birthPlace',form.birthPlace);
    formData.append('objetive',form.objetive);
    formData.append('objetiveKnown',form.objetiveKnown);
    formData.append('amountpermonth',form.amountpermonth);
    axios.post(`${URL}client/save-child/EAVId0b1Ss`,formData)
    .then(res=>{
      if(res.data.status == 200){
        cleanForm()
        }
        });
  }
  }
  const handleForm = (e)=>{
    e.preventDefault()
    cleanForm()
   setIsChild(true);
   setIsCont(false)
   if(isChild){
    setIsChild(false);
    setIsCont(true)
   }

  }
  useEffect(() => {
    if(show)
    setTimeout(() => {
      setShow(false);
    },5000)
  },[show, isChild, isCont]);
  return (
    <Row style = {{paddingTop:"5%"}}>
         <Col lg = "6">
        <img className = {style.img} src = {Lapiz} style = {{zIndex:"10" ,position:"absolute", top: "268px", left: "600px"}}/>
     <div className = {style.img2} style = {{position:"absolute", top: "268px", left: "640px", zIndex:"0"}}>
     <Circulo/> 
     </div>
         </Col>
        <Col  lg = "6" className = {style.colXl}>
        <Card className = {style.card} >
        <Card.Title style={{marginLeft:"5px"}} className = {style.titleF}>{isCont?"Datos Aportante":"Datos Del Menor"}</Card.Title>
      <Card.Body style = {{margin:"auto"}}>
        
        <Form onSubmit={e=>handleSubmit(e)} onChange = {e=>handleChange(e)} >
      <Form.Group className="mb-3">
        <Row>
        <Col lg = {12}>
        <Form.Control style = {{width:"400px"}} type="text" placeholder="Nombre" id = "username" value = {form.username}/>
        </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3">
        <Row>
        <Col lg = {12}>
        <Form.Control type="text" placeholder="Apellido Paterno" id = "lastName" value = {form.lastName}/>
        </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3"  >
        <Row>
        <Col lg = {12}>
        <Form.Control type="text" placeholder="Apellido Materno" id = "mothersLastName" value = {form.mothersLastName} />
        </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3">
        <Row>
        <Col lg = {12}>
        <Form.Control type="text" placeholder="Estado de nacimiento" id = "birthPlace" value = {form.birthPlace}/>
        </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3" style ={{display:isChild?null:"none"}} >
        <Row>
        <Col lg = {12}>
        <Form.Control type="date" placeholder="Fecha" id = "dateOfBirth" value = {form.dateOfBirth}/>
        </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3">
        <Row className={style.customInputs}>

        <Col lg = {4} style ={{zIndex:"11",display:isCont?null:"none"}}>
        <Form.Control type="file"  placeholder="INE" id = "INE" style ={{display:"none"}} />
        <Form.Label for = "INE" style = {{background:isUp.INE?"green":null}}>INE</Form.Label>
        </Col>
        <Col lg = {4} style ={{display:isCont?null:"none"}}>
        <Form.Control type="file" placeholder="INE" style ={{display:"none"}} id = "RFC"/>
        <Form.Label for = "RFC" style = {{background:isUp.RFC?"green":null}}>RFC</Form.Label>
        </Col>
        <Col lg = {4} style = {{margin:isChild?"auto":null}}>
        <Form.Control type="file" style ={{display:"none"}} placeholder="INE" id = "ACTA" />
        <Form.Label for = "ACTA" style = {{background:isUp.ACTA?"green":null}}>ACTA</Form.Label>
        </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3"  style ={{display:isCont?null:"none"}}>
        <Row>
        <Col lg = {12}>
        <Form.Control type="text" placeholder="Parentesco con el menor" id = "relationship" value = {form.relationship}/>
        </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3"  style ={{display:isCont?null:"none"}}>
        <Row>
        <Col lg = {12}>
        <Form.Control type="email" placeholder="Correo electronico" id = "email" value = {form.email}/>
        </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3"  style ={{display:isCont?null:"none"}}>
        <Row>
        <Col lg = {12}>
        <Form.Control type="text" placeholder="Celular" id = "phone" value = {form.phone}/>
        </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3"  style ={{display:isChild?null:"none"}}>
        <Row>
        <Col lg = {12}>
        <Form.Control type="text" placeholder="Objetivo" id = "objetive" value = {form.objetive}/>
        </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3" style ={{display:isChild?null:"none"}}>
        <Row>
        <Col lg = {12}>
        <Form.Control type="text" placeholder="Monto que desea aportar mensualmente" id = "amountpermonth" value = {form.amountpermonth}/>
        </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3">
      <Row className = {style.customButtons}>
        <Col lg = {7}>
        <Button variant="primary" type="submit" style = {{width:"108px", fontSize:"16px"}}>
        Guardar
      </Button>
      </Col>
      <Col lg = {5}>
          <Button variant="primary" onClick={e=>handleForm(e)} style = {{width:"108px", fontSize:"16px"}}>
        {isChild?"Regresar":"Continuar"}
      </Button>
      </Col>
      </Row>
      </Form.Group>
    </Form>
      </Card.Body>
    </Card>
        </Col>
      </Row>
  );
}

export default Container;