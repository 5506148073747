import React,{useEffect, useState} from "react";
import { TbBrandSpotify } from 'react-icons/tb';
import { GrInstagram } from 'react-icons/gr';
import { RiFacebookCircleLine } from 'react-icons/ri';
import { FiArrowDownCircle } from 'react-icons/fi';
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  FooterLinkI,
  Icon,
  Heading,
} from "./FooterStyles";
  
const Footer = () => {
    const [show, setShow] = useState(false);
    const handleFooter = (e) => {
      e.preventDefault();
      if(!show)
      setShow(true);
      else
      setShow(false);
    };
    useEffect(() => {
        
        }, []);
  return (
    <div style = {{display: 'flex', flexDirection: 'column', zIndex:"10000"}}>
        <div style = {{marginLeft:"49%",position:"fixed", bottom:!show?"0":"300px", zIndex:100, backgroundColor:"#EEA6A6", borderRadius:"90%"}}>
        <button onClick={e=>handleFooter(e)} style = {{outline: 'none', border:"none",background:"transparent"}}>
        <FiArrowDownCircle size = "40"/>
        </button>
        </div>
    
   { show?<Box>
      <Container>
        <Row>
          <Column>
            <Heading>Blog</Heading>
            <FooterLink target = "_blank" href="https://fondocometa.wordpress.com/">info@fondocometa.mx</FooterLink>
          </Column>
          <Column>
          <Heading>Contactanos</Heading>
            <FooterLink target = "_blank" href="#">(477) 781-28-96</FooterLink>
            <FooterLink target = "_blank" href="#">3317181012</FooterLink>
          </Column>
          <Column>
          <Heading>Privacidad</Heading>
            <FooterLink target = "_blank" href="#">Politica de privacidad</FooterLink>
            <FooterLink target = "_blank" href="#">Aviso de privacidad</FooterLink>
         
          </Column>
          <Column>
          <Heading>Servicio</Heading>
            <FooterLink target = "_blank" href="#">Terminos y condiciones</FooterLink>
          </Column>
          <Column>
          <Heading>Dirección</Heading>
            <FooterLink target = "_blank" href="#">Blvd. Campestre 2509-101 Col.<br/>Barranca del Refugio León, Gto.</FooterLink>
          </Column>
          <Column>
            <Heading>Redes sociales</Heading>
            <FooterLinkI target = "_blank" href="#">
              <Icon target = "_blank" href="https://www.facebook.com/fondoeducativocometa/" style = {{marginRight:"10px"}}>
                <RiFacebookCircleLine size={40}/>
              </Icon>
              <Icon target = "_blank" href="https://www.instagram.com/fondocometa/" style = {{marginRight:"10px"}}>
                <GrInstagram size={30}/>
              </Icon>
              <Icon target = "_blank" href="" >
            <TbBrandSpotify size={40}/>
            </Icon>
            </FooterLinkI>
            
           
          </Column>
        </Row>
      </Container>
    </Box>:null}
    </div>
  );
};
export default Footer;