
import style from './style.module.css'
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Simulator from '../Simulator';
import Coin from "../../utils/img/coin.png";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DinamicTable from '../DinamicTable'
import Carrusel from '../PlanContainer';
import {Link} from 'react-router-dom'
import { BsTrash } from 'react-icons/bs'; 
import {useSelector} from 'react-redux';
import { useNavigate } from "react-router-dom";
import Modal from './Modal';
import {URL} from '../../actions/constants';
function Container() {
  const navigate = useNavigate();
  const [isAuth,setAuth] = useState(sessionStorage.getItem("isAuth"))
  const [defaultCard, setDefaultCard] = useState(true)
  const [data, setData] = useState([]);
  const userId = useSelector(state => state.userId);
  const handleDelete = (e)=>{
    e.preventDefault()
    let card = data.filter(card => card.objectId == e.target.id);
    axios.get(`${URL}client/deletecard/${card.stripeId}/${card.objectId}`).then(res => {
     if(res.data.status == 200){
      axios.get(`${URL}client/cards/${userId}`).then(res => {
        console.log(res.data.cards)
        setData(res.data.cards)
       })
     }
     })

  }
  const handleDefault = (e)=>{
    e.preventDefault()
    let card = data.filter(card => card.objectId == e.target.id);
    axios.get(`${URL}client/setdefaultcard/${card.stripeId}/${card.objectId}`).then(res => {
     if(res.data.status == 200){
      axios.get(`${URL}client/cards/${userId}`).then(res => {
        console.log(res.data.cards)
        setData(res.data.cards)
       })
     }
     })
     if(!isAuth){
      navigate('/login')
    }

  }
  useEffect(() =>{
      axios.get(`${URL}client/cards/${userId}`).then(res => {
       console.log(res.data.cards)
       setData(res.data.cards)
      })
  },[])
  return (
    <Row className={style.container}>
      
      <Col lg="12">
        <DinamicTable headers={["Tipo", "Tarjeta", "Acciones"]}
          title={"Agregar tarjeta"} titleb={"Agregar tarjeta"}>
            {
              data.map((card, index) => 
              <tr>
              <td>
               { card.type}
              </td>
              <td>
              {`***************${card.cardNumber}`}
              </td>
              <td>
              <Row style = {{backgroundColor: "white"}}>
                <Col lg = {3} style = {{backgroundColor: "white"}}>
                  <form onChange = {e=>handleDefault(e)}>
    <label  for="defaultCard" style = {{fontSize:"30px",color:card.defaultCard?"gold":null}}>★</label>
    <input style = {{display:"none"}} id={card.objectId} type="radio" name="estrellas" value="4"/>
</form>
                </Col>
                <Col lg = {3} style = {{backgroundColor: "white"}}>
                <button onClick = {e=>handleDelete(e)} style = {{backgroundColor: "red",outline:"none",border: "none"}}><BsTrash/></button>
                  </Col>
                  
                </Row>
              </td>
            </tr>
              )
            }
           
          
        </DinamicTable>
      </Col>
    </Row>
  );
}

export default Container;