import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as SP } from "../../utils/img/SPOTIFY.svg";
import { ReactComponent as FB } from "../../utils/img/FACEBOOK.svg";
import { ReactComponent as IT } from "../../utils/img/INSTAGRAM.svg";
import Nina from "../../utils/img/nina.png";
import Nino from "../../utils/img/nino.png";
import CirculoNinos from "../../utils/img/circuloNinos.png";
import CirculoSenora from "../../utils/img/circuloSenora.png";
import Linea from "../../utils/img/linea3.png";
import Simulator from '../Simulator';
import { useNavigate } from "react-router-dom";
function Home() {
  const navigate = useNavigate();
  const handleRegister = ()=>{
    navigate("/register");
  }
  const handleKnow = ()=>{
    navigate("/how-start");
  }
  return (
    <>
      <Row className={"mt-5"}>
        <Col xs = {8} md = {8} lg = {8}>
          <div className={style.title}>
            <p>
              La herramienta de ahorro que pagará la universidad de tus hij@s.
            </p>
            <p className={style.subtitle}>
              Somos una comunidad de padres que buscan un mejor futuro para sus
              hijos, por ello hemos creado Fondo Cometa, el primer ahorro
              sustentable creado con el apoyo de un gran banco mexicano y la
              Casa de Bolsa más grande de México.
            </p>
            <button onClick={e=>handleKnow()}>Conoce más</button>
            <button onClick = {e=>handleRegister()} className="ms-5">Registro</button>
          </div>
        </Col>
        <Col >
          <Row className={style.containerImage}>
            <Col lg="12">
              <img src={CirculoNinos} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className=" justify-content-center" style = {{marginTop:"100px"}}>
        <Col lg="4">
          <Row className={style.containerImage}>
            <Col lg="12">
              <img src={CirculoSenora} />
            </Col>
          </Row>
        </Col>
        <Col lg="4">
          <div className={style.titleBlackF}>
            <p>
            Planifica un mejor futuro para la próxima gran generación.
            </p>
            <p className={style.subtitleb}>
            Datos del IMCO señalan que estudiar una licenciatura en México te permite ganar en promedio un 80%1 más que una persona que solo terminó el bachillerato. En Fondo Cometa trabajamos para que más niños tengan acceso a la mejor educación posible y que la próxima generación, sea la más preparada de la historia de nuestro país. ¿Sabes cuánto debes de ahorrar para pagar los estudios de licenciatura de tus hij@s?
            </p>
          </div>
        </Col>
      </Row>

      <Row className=" justify-content-center" style = {{marginTop:"100px"}}>
      <Col lg="5">
          <div className={style.title}>
            <img src={Linea} />
          </div>
        </Col>
        <Col lg="5" className = "mt-5">
         <Simulator/>
        </Col>
        
      </Row>
    </>
  );
}

export default Home;
