import {
 AUTH_USER,GET_RESULTS,SET_PLAN,USER_DATA
} from '../actions/constants.js';

const initialState = {
   plans:[],
   plan:{
    choosen: true,
recommendation: "$∞",
recommendation2: "$1,000.00",
totalContributions: 0,
totalCost: "$702,284.08",
totalSaving: "$2,000.00",
type: "none"
},
   from_login:null,
   persentage:"",
   simulatedData:{},
   status:null,
   userId:'',
   haveDocuments:true,
   stripeId:'',
   authUser:false,
   name:''
}
export default function reducer(state = initialState, { type, payload }) {
    switch (type) {
	case AUTH_USER:
	    return{
		...state,
		authUser:payload.isAuth
	   }
        case GET_RESULTS:
            let plans = [];
            plans.push(payload.plans['regular'])
            plans.push(payload.plans['medium'])
            plans.push(payload.plans['high'])
           
            let plan = payload.plans['regular'].choosen?payload.plans['regular']:payload.plans['medium'].choosen?payload.plans['medium']:payload.plans['high'].choosen?payload.plans['high']:payload.plans['high'];
            return {
                ...state,
                plans:plans,
                plan:plan,
                from_login:payload.from_login,
                persentage:payload.persentage,
                simulatedData:payload.simulatedData,
                status:payload.status,
                index:payload.index
            }
        case SET_PLAN:
            console.log(payload);

            return{
                ...state,
                plan:payload
        }
        case USER_DATA:
            return{
                ...state,
                userId:payload.userId,
                haveDocuments:payload.haveDocuments,
                stripeId:payload.stripeId,
                authUser:payload.authUser,
                name:payload.name
            }
        default:
            return state;
    }
}

