import Toast from 'react-bootstrap/Toast';
import style from './style.module.css'
import { Col, Row, Card, Form, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Simulator from '../Simulator';
import axios from 'axios';
import Coin from "../../utils/img/coin.png";
import React,{useEffect, useState} from 'react';
import Carrusel from '../PlanContainer';
import {useSelector} from 'react-redux';
import { useNavigate } from "react-router-dom";
import {URL} from '../../actions/constants';
function Container({totalCost, recommendation, totalSaving}) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false)
  const [files, setFiles] = useState([])
  const userId = useSelector(state => state.userId);
  const plann = useSelector(state => state.plan)
  const haveDocuments = useSelector(state=> state.haveDocuments)
  const handleChange = (e) =>{
    e.preventDefault()
   files.push(e.target.files[0])
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (files.length >=2) {
    let formI = new FormData();
    formI.append('files',files[0])
    formI.append('files',files[1])
    formI.append('files',files[2])
    axios.post(`${URL}client/save-docs/${userId}`,formI)
    .then(res=>{
      if(res.data.status == 200){
        setShow(true);
      }
      console.log(res.data)
    });
  }
  }
  const [isAuth,setAuth] = useState(sessionStorage.getItem("isAuth"))
  useEffect(() => {
    if(show)
    setTimeout(() => {
      setShow(false);
    },5000)
  },[show]);
  return (
    <Row className={style.container} style = {{paddingTop:"8%", marginLeft:haveDocuments?"35%":null}}>
        <Col lg = {haveDocuments?12:5}>
        <Card className = {style.card}>
      <Card.Body style = {{margin:"auto"}}>
        <Card.Title className = {style.titleF + " " + style.centerText}>Tu plan</Card.Title>
        <img src = {Coin} style = {{ marginLeft: "6%"}}/>
        <Card.Subtitle className = {style.subtitleF+" mb-2"} >Universidades de costo</Card.Subtitle>
        <div className="row bolder">
        <div className={style.textBlue+" col s6 left-align"}>
         Costo Total:
        </div>
        <div className={style.textBlue+" col s6 right-align"}>{plann.totalCost}</div>
      </div>
      <div className="row bolder">
        <div className={style.textBlue+" col s6 left-align"}>
        Aportación recomendada:
        </div>
        <div className={style.textBlue+" col s6 right-align"}>{plann.recommendation}</div>
      </div>
      <div className="row bolder">
        <div className={style.textBlue+" col s6 left-align"}>
         Aportaciones totales:
        </div>
        <div className={style.textBlue+" col s6 right-align"}>{plann.totalSaving}</div>
      </div>
      </Card.Body>
    </Card>
        </Col>
        <Col lg = "6"  className = {style.form}>
       { show?<Toast
        className="d-inline-block m-1"
        bg={"success"}
      >Documentos enviados con exito!</Toast>:null}
        {!haveDocuments?<Form onChange={e=>handleChange(e)} onSubmit={e=>handleSubmit(e)}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <p>Ayúdanos con los siguientes documentos <br/>(Deberán estar en PDF o JPGE).</p>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Row>
        <Col lg = {7}>
        <Form.Control type="file" style={{display:"none"}} placeholder="INE" />
        <Form.Control type="text" disabled placeholder="INE" />
        </Col>
        <Col lg = {5}>
        <Form.Label>Adjuntar</Form.Label>
        </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3" controlId="2">
        <Row>
        <Col lg = {7}>
        <Form.Control type="file" style={{display:"none"}} placeholder="RFC" />
        <Form.Control type="text" disabled placeholder="RFC" />
        </Col>
        <Col lg = {5}>
        <Form.Label>Adjuntar</Form.Label>
        </Col>
        </Row>
      </Form.Group>

      <Form.Group className="mb-3" controlId="1">
        <Row>
        <Col lg = {7}>
        <Form.Control type="file" style={{display:"none"}} placeholder="Comprobante de domicilio" />
        <Form.Control type="text" disabled placeholder="Comprobante de domicilio" />
        </Col>
        <Col lg = {5}>
        <Form.Label>Adjuntar</Form.Label>
        </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
          <Button variant="primary" type="submit" style = {{marginLeft:"55%", width:"108px"}}>
        Enviar
      </Button>
      </Form.Group>
    </Form>:null}
        </Col>
      </Row>
  );
}

export default Container;