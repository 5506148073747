import React from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {Link} from 'react-router-dom';
import { useState, useEffect } from "react";
import NavBar from "../NavBar";
import style from "./styles.module.css";
import { Row, Col, Container, ListGroup } from "react-bootstrap";
import {URL} from '../../actions/constants';
import MitadNino from "../../utils/img/ninosolo.png";
const SideBar = () => {
  const userId = useSelector(state=>state.userId)
  const name = useSelector(state=>state.name)
  const [id, setId] = useState('');
  useEffect(()=>{
    axios.get(`${URL}client/plan/${userId}`)
    .then(res=>{
      console.log(res.data.id);
      setId(res.data.id);
    })
  },[])
  return (
    <Row className={style.sideBar}>
      <Col lg = {8}>
      <Col lg = {12} className = {style.logo+" d-flex justify-content-center"}>
      <img src = {MitadNino} style = {{marginTop:"20px"}}/>
      <span>{name}</span>
      </Col>
      <Col lg = {12}>
     <ul className = {style.list}>
      <li>
      <Link style={{ color: 'inherit', textDecoration: 'inherit'}} to ={`/profile/personal-info/${userId}`}>Datos Personales</Link>
      </li>
      <div className = {style.separator}></div>
      <li>
      <Link style={{ color: 'inherit', textDecoration: 'inherit'}} to ={`/profile/contracts/${userId}`}>Saldos y contratos</Link>
      </li>
      <div className = {style.separator}></div>
      <li>
      <Link style={{ color: 'inherit', textDecoration: 'inherit'}} to ={`/profile/payments/${userId}`}>Metodos de pago</Link>
      </li>
      <div className = {style.separator}></div>
      <li>
      <Link style={{ color: 'inherit', textDecoration: 'inherit'}} to ={`/profile/documents/${userId}`}>Tramites y documentos</Link>
      </li>
      <div className = {style.separator}></div>
     </ul>
     </Col>
     </Col>
    </Row>
  );
};

export default SideBar;
