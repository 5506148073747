
import style from './style.module.css'
import { Col, Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Simulator from '../Simulator';
import CirculoNino from "../../utils/img/circuloNino.png";
import React,{useEffect, useState} from 'react';
import Carrusel from '../PlanContainer';
function Container() {
  const [show, setShow] = useState(true)
  return (
    <Row className={style.container}>
        <Col lg = "6" style = {{margin:"auto"}}>
        <Simulator/>
        </Col>

         
       
      </Row>
  );
}

export default Container;