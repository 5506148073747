import React,{useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import style from './style.module.css'
import { Button, Col, Form, Row, Card} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from "react-router-dom";
import { responsivePropType } from 'react-bootstrap/esm/createUtilityClasses';
import SimulatorContainer from '../PlanContainer';
import {getResults} from '../../actions';
import {URL} from '../../actions/constants';
function Simulator() {
  const result =  useSelector(state => state.simulatedData)
  const plan =  useSelector(state => state.plan)
  const [show, setShow] = useState(false)
  const [form, setForm] = useState({
    childname:"",
    childage:"",
    firstContribution:"",
    mensualityContribution:""
  })
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOnChange = (e)=>{
    e.preventDefault()
    if(e.target.id === "childname"){
      setForm({...form,childname:e.target.value})
    }else if(e.target.id === "childage"){
      setForm({...form,childage:e.target.value})
    }else if(e.target.id === "firstContribution"){
      setForm({...form,firstContribution:e.target.value})
    }else{
      setForm({...form,mensualityContribution:e.target.value})
    }
    console.log(e.target.value)
  }
  const handleSubmit = (e)=>{
    e.preventDefault()
    axios.post(`${URL}results`,form)
    .then(res=>{
      console.log(res)
       if(res.data.status == 200){
        dispatch(getResults(res.data))
       navigate('/register');
       }
    })
  }
  const handleRegreat = (e)=>{
    navigate(`/simulator`);
  }
  const handleClick = (e)=>{
    e.preventDefault()
    setShow(!show)
    navigate('/login');
  }
  useEffect(() =>{
    if(!result){
    axios.post(`${URL}results`,form)
    .then(res=>{
      console.log(res)
       if(res.data.status == 200){
        dispatch(getResults(res.data))
       }
    })}
  },[result])
  return (
    <Row style = {{justifyContent:"center"}}>
      <Col style = {{marginTop:"90px"}}lg = {12}>
      <h1 style = {{textAlign:"center", marginBottom:"60px"}}>Resultados Simulador</h1>

      </Col>
      <Col lg = {6}>
      <Card body>
        <span>Mi hijo se llama </span> <span>{result.childname}</span>
      </Card>
      <Card body>
        <span>Su edad es </span> <span>{result.childage}</span>
      </Card>
      <Card body>
        <span>Mi aportación inicial seria de </span><span>{result.firstContribution}</span>
      </Card>
      <Card body>
        <span>Quiero aportar mensualmente </span><span>{result.mensualityContribution}</span>
      </Card>
      <Button style = {{width:"150px",height:"40px",backgroundColor: "#002D62", color: "#fff", marginTop:"30px"}} variant="primary" onClick={e=>handleRegreat(e)}>
       Volver a simular
        </Button>
      </Col>
      <Row style = {{justifyContent:"center"}}>
      <Col lg = {6} style = {{marginTop:"100px"}}>
      <Card body>
        <div>
        </div>
        <div>
        <span>Rendimiento anual estimado </span><span>6.5%</span>
        </div>
        <div>
        <span>Meses Calculados </span><span>192</span>
        </div>
        <div>
        <span>El monto estimado de tu fondo sería </span><span>{plan.totalCost}</span>
        </div>
      </Card>
      </Col>
      <Col style = {{marginTop:"40px"}}lg = {12}>
      <h1 style = {{textAlign:"center", marginTop:"60px"}}>Paso 2: Selecciona tu objetivo de ahorro de acuerdo<br/> al tipo de universidad</h1>

      </Col>
      <Col lg = {12}>
      <SimulatorContainer total = {plan.totalCost}/>
      </Col>
      </Row>
      </Row>
  );
}

export default Simulator;