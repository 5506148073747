import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Form, Col, Row} from 'react-bootstrap';
import { height } from '@mui/system';
function Example({url, id, callback, subscribe = true}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button disabled = {subscribe?false:true} variant="primary" onClick={e=>{callback(id); setShow(true)}}>
        Aportar
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Aportación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Enlace de aportación</Form.Label>
        <Row>
        <Col lg = {9}>
        <Form.Control readonly type="text" value={url} />
        </Col>
        <Col lg = {3}>
        <Button variant="success" onClick={() => {navigator.clipboard.writeText(url)}}>
        Copiar
      </Button>
        </Col>
      </Row>
        <Form.Text className="text-muted">
          Enlace generado para realizar aportaciones.
        </Form.Text>
      </Form.Group>
    </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <a style = {{background:"#002D62 0% 0% no-repeat padding-box", textDecoration:"none", color:"white",
        padding:"7px 10px", borderRadius:"5px"}} href = {url} target = "_blank" variant="primary">
            Aportar
          </a>
        </Modal.Footer>
      </Modal>
    </>
  ); 
}

export default Example;