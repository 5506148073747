import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import {Form, Col, Row} from 'react-bootstrap';
import { height } from '@mui/system';
import { useNavigate } from "react-router-dom";
import {URL} from '../../actions/constants';
function Example({userId, id, callback, subscribe, haveCC}) {
    const handleSubscription = (id)=>{
        axios.post(URL+'subscription/create-new-subscription', {id})
        .then(res => {
            console.log(res)
            setShow(false)
        })
        .catch(error => {
           console.log(error)
           setShow(true)
        });
      }
  const [show, setShow] = useState(false);
  const [cc, setCC] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => {
    axios.get(`${URL}client/cards/${userId}`).then(res => {
        console.log(res.data.cards)
        if(res.data.cards.length > 0) {
        setCC(true)
        }else{
            setCC(false)
        }
        setShow(true)
       })
    
};
  const handleSubmit = (id)=>{
    if(cc){
        handleSubscription(id)
        
    }else{
        navigate(`/profile/payments/${userId}`)
    }
  }

  return (
    <>
      <Button disabled = {subscribe?false:true} variant="primary" onClick={e=>handleShow(e)}>
        Activar
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Activar Subscripcion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Esta seguro de realizar esta acción?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="primary" onClick = {e=>handleSubmit(id)}>
           { cc ? "Activar":"Agregar metodo de pago"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ); 
}

export default Example;