import React,{useState} from 'react';
import { Table, Dropdown, DropdownButton, Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../ProfileComponents/Modal'
import Mitad from "../../utils/img/mitad.png";
import Pagination from '../Pagination';
import style from './styles.module.css';
const DinamicTable = ({headers, children, title, titleb, showImg , isB = false})=>{
  const [form, setForm] = useState({
    card_number:'',
    cvv:'',
    exp_date:'',
    type:''
  })
  const [showImage, setShowImage] = useState({
    visa:false,
    master:false,
    american:false
  })
  const handleSubmit = (e)=>{

  }
  const cleanForm = ()=>{
    setForm({
      card_number:'',
      cvv:'',
      exp_date:'',
      type:''
    })
  }
  const handleForm = (e)=>{

    if (e.target.id == 'card_number'){
      // let validator = new RegExp('[0-9]{15,16}|(([0-9]{4}\s){3}[0-9]{3,4})')
      let validator = new RegExp('^[0-9,$]*$')
      let num = e.target.value;
      if(validator.test(num)){
        setForm({...form,card_number:num})
      }
    }else{
      let formAux = form
  formAux[e.target.id] = e.target.value;
  setForm({
    ...formAux,
  })
    }
    // if (e.target.id == 'exp_date'){
    //   // let validator = new RegExp('[0-9]{15,16}|(([0-9]{4}\s){3}[0-9]{3,4})')
      
    //   let num = e.target.value;
    //   let aux = num.split('/')
    //   if(aux.length > 1){
    //     setForm({...form,exp_date:num})
    //   }
    // }
    
  }
  const handleChange = (e) => {
    e.preventDefault();
    console.log(e.target.id)
    if(e.target.id == "Visa"){
    setShowImage({
      ...showImage,
      visa:true,
      master:false,
      american:false
    })
  }else if(e.target.id == "Mastercard"){
    setShowImage({
      ...showImage,
      visa:false,
      master:true,
      american:false
    })
  }else if(e.target.id == "American Express"){
    setShowImage({
      ...showImage,
      visa:false,
      master:false,
      american:true
    })

  }
    }
    return(
      <div className = {style.container} style = {{maxHeight:isB?"140px":null, overflow:"hidden"}}>
     
       { showImg?<img style = {{width:"350px"}} src = {Mitad}/>:null}
      {showImg?<Modal titleB = {titleb} title={title} form = {form} cleanForm={cleanForm}>
      <div >
      <form onChange = {e=>handleForm(e)} onSubmit = {e=>handleSubmit(e)}className={style.creditinfo}>
          <div className={style.creditinfocontent}>
            <div className={style.halfinputtable} style = {{marginBottom: "20px"}}>
            <Row>
            <Col lg = {6}>
            <span>Seleccione el tipo de tarjeta</span>
              </Col>
              <Col lg = {6}>
              <DropdownButton  id="cards" title="Dropdown button">
      <Dropdown.Item onClick={e=>handleChange(e)} id = "Mastercard">Mastercard</Dropdown.Item>
      <Dropdown.Item onClick={e=>handleChange(e)} id = "Visa">Visa</Dropdown.Item>
      <Dropdown.Item onClick={e=>handleChange(e)} id = "American Express">American Express</Dropdown.Item>
    </DropdownButton>
            </Col>
            </Row>
           
            </div>
    
           { showImage.visa?<img src='https://dl.dropboxusercontent.com/s/ubamyu6mzov5c80/visa_logo%20%281%29.png' height='80' className={style.creditcardimage} id='creditcardimage'></img>:null}
           { showImage.master?<img src='https://upload.wikimedia.org/wikipedia/commons/thumb/7/72/MasterCard_early_1990s_logo.png/1200px-MasterCard_early_1990s_logo.png' height='80' className={style.creditcardimage} id='creditcardimage'></img>:null}
           { showImage.american?<img src='https://e7.pngegg.com/pngimages/882/286/png-clipart-american-express-credit-card-membership-rewards-credit-limit-credit-card-blue-text.png' height='80' className={style.creditcardimage} id='creditcardimage'></img>:null}
            Numero de tarjeta
            <input className={style.inputfield} id = "card_number" value = {form.card_number}></input>
            <div className={style.halfinputtable}>
              <tr>
                <td> Expiracion
                  <input className={style.inputfield} id = "exp_date" value = {form.exp_date}></input>
                </td>
                <td>CVC
                  <input className={style.inputfield} id = "cvc" value = {form.cvc}></input>
                </td>
              </tr>
            </div>
          </div>
</form>
        </div>
      </Modal>:null}
        <Table className= {style.colXl} id = {'tableBalance'}>
        <thead className="thead-primary">
    <tr>
      {headers&&headers.map((item, index) => (
        <th key={index}>{item}</th>
      ))
      }
    </tr>
  </thead>
  <tbody
  >
    {children}
  </tbody>
  
</Table>

</div>
    );
}

export default DinamicTable;