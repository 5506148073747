import React,{useEffect, useState} from 'react';
import { useDispatch } from 'react-redux/es/exports';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import style from './style.module.css'
import { Button, Card, Form, Row, Col,Container, Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import MitadNino from "../../utils/img/mediolol.png";
import { ReactComponent as Circulo } from "../../utils/img/circuloVerde.svg";
import {getUser} from '../../actions';
import Toast from 'react-bootstrap/Toast';
import {URL} from '../../actions/constants';
function Login() {
  const dispatch = useDispatch();
  const [isAuth,setAuth] = useState(sessionStorage.getItem("isAuth"))
  const [show, setShow] = useState(false)
  const [showM, setShowM] = useState(false)
  const [form, setForm] = useState({
    username:"",
    password:"",
  })
  const navigate = useNavigate();
  const handleOnChange = (e)=>{
    e.preventDefault()
    if(e.target.id === "username"){
      setForm({...form,username:e.target.value})
    }else{
      setForm({...form,password:e.target.value})
    }
    
  }
  const handleSubmit = (e)=>{
    e.preventDefault()
    axios.get(`${URL}auth/login/${form.username}/${form.password}`)
    .then(res=>{
      console.log(res)
      if(res.data.isAuth){
        // sessionStorage.setItem("userId", 'xuU6BKMq29');
        sessionStorage.setItem("haveDocuments", res.data.haveDocuments);
        sessionStorage.setItem("userId", res.data.userId);
        // sessionStorage.setItem("stripeId",'cus_KkgqrTOXquwYHU');
        sessionStorage.setItem("stripeId",res.data.stripeId);
        sessionStorage.setItem("isAuth",true);
        dispatch(getUser({userId:res.data.userId, haveDocuments:res.data.haveDocuments,stripeId:res.data.stripeId,name:res.data.name, authUser:true}))
       navigate(`/profile/documents/${res.data.userId}`);
          //dispatch(getUser({userId:res.data.userId, haveDocuments:res.data.haveDocuments,stripeId:res.data.stripeId}))
        //navigate(`/profile/documents/${res.data.userId}`);
      }
    }).catch(error => {
     setShow(true)
    })
    console.log(form)
  }
  const handleSend = (e)=>{
    axios.post(`${URL}auth/reset-password`,{email:form.username})
    .then(res=>{
      setShowM(false)
    })
  }

  const handleClick = (e)=>{
    navigate('/register');
  }
  const handleShow = (e)=>{
    setShow(true)
  }
  const handleShowM = (e)=>{
    setShowM(true)
  }
  const handleClose = (e)=>{
    setShowM(false)
  }
  useEffect(()=>{
   // sessionStorage.clear()
    if(show){
      setTimeout(()=>{
        setShow(false);
    },5000)
    console.log(isAuth)
  }
  
  },[show,showM])
  return (
    <Row className = {style.container}>
      <Col lg = {1}>
     <img src = {MitadNino} className = {style.pos}/>
    
    
     </Col>
     <Col xl = {7}>
    <Card className = {style.simulator}>
      <Card.Body>
        <Card.Title className = {style.titleF}>¡Bienvenido!</Card.Title>
        
        <Form onSubmit={e=>handleSubmit(e)}>
      <Form.Group className="mb-3" controlId="username">
        <Form.Control type="email" placeholder="Correo" autocomplete={false} style = {{width:"80%", margin:"auto"}} onChange = {e=>handleOnChange(e)} value = {form.username}/>
     
      </Form.Group>

      <Form.Group className="mb-3" controlId="password">
        <Form.Control type="password" placeholder="Contraseña" autocomplete="off" style = {{width:"80%", margin:"auto"}}
        onChange = {e=>handleOnChange(e)} value = {form.password}/>
        <Form.Text className="text-muted">
        { show?<div style = {{marginLeft:"65px"}}><Toast
        className="d-inline-block m-1"
        bg={"danger"}
      >Usuario y o contraseña incorrectos!</Toast></div>:null}
          <Button variant="primary" type="submit" style = {{marginLeft:"40%", marginTop:"20px"}}>
        Iniciar sesion
      </Button>
      <Button variant="primary" onClick={e=>handleClick(e)} style = {{marginLeft:"40%", marginTop:"20px"}}>
        Crear cuenta
      </Button>
      <br/>
      <a onClick = {e=>handleShowM(e)} style = {{marginLeft:"38%", marginTop:"75px"}}>Recuperar contraseña</a>
        </Form.Text>
      </Form.Group>
    </Form>
      </Card.Body>
    </Card>
    </Col>
    <Modal  size="md" show={showM} scrollable={true} onHide={handleClose} backdrop = 'static'>
          <Modal.Header closeButton>
            <Modal.Title>Recuperar Contraseña</Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
        <Container>
      
          <Row>
            <Col xs={12} md={12}>
            <Form.Group className="mb-3" controlId="childage">
        <Form.Label>Email</Form.Label>
        <Form.Control type="text" placeholder="Ingrese la direccion de correo" onChange = {e=>handleOnChange(e)} value = {form.username}/>
      </Form.Group>
            </Col>
          </Row>
          </Container>
        </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={e=>handleClose(e)}>
              Cerrar
            </Button>
            <Button style = {{backgroundColor: "#002D62"}} variant="primary" onClick={e=>handleSend(e)}>
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
    </Row>
  );
}

export default Login;