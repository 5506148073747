
import style from './style.module.css'
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import Pagination from '../Pagination';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Simulator from '../Simulator';
import Coin from "../../utils/img/coin.png";
import React, { useEffect, useState } from 'react';
import DinamicTable from '../DinamicTable'
import Carrusel from '../PlanContainer';
import {Link, useParams } from 'react-router-dom';
import Modal from './ModalUpdate';
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import {URL} from '../../actions/constants';
function Container() {
  const navigate = useNavigate();
  const [show, setShow] = useState(true)
  const [data, setData] = useState([])
  const [child, setChild] = useState({})
  const [active, setActive] = useState(0)
  const [totalContribution, setTotalContribution] = useState(0)
  const [rendimiento, setRendimiento] = useState(0)
  let [init, setInit] = useState(0)
  let [limit, setLimit] = useState(1)
  let { id } = useParams();
  const [isAuth,setAuth] = useState(sessionStorage.getItem("isAuth"))
  const handlePosition = (e)=>{
    let position = (+e.target.id)
    console.log(position)
    setActive(position)
    if(position > active){
      setInit(limit+1)
      setLimit(limit+2)
    }else if(position < active){
      setInit(init-2)
      setLimit(init-1)
    }
}
const getAmount = (amount, isObjective) =>{
    if(isObjective){
      setChild({
        ...child,
        objetive:amount
      })
    }else{
      setChild({
        ...child,
        pricePlan:amount
      })
    }
}
const handleRegreat = (e)=>{
  navigate(`/profile/contracts/${id}`);
}
const generatePdf = ()=>{
  var doc = new jsPDF();
  doc.text("FONDO COMETA",85,10)
  doc.text("Año      Mes       Aportacion       Tipo de aportacion       Comision         Neto", 10, 30);

let x , y = 40
data.map((child, index) =>{
doc.text(`${child.createdAt.split("-")[0] + "     "+child.createdAt.split("-")[1] + "          $"+child.contribution+"                 "+child.contributionType
+"                 $"+child.comission+"               $"+child.amount}`,10, y)
y+=y+10
}
)
doc.save("Balance.pdf");
}
  useEffect(() =>{
    axios.get(`${URL}client/balance/${id}`).then(res => {
       console.log(res.data)
     setData(res.data.childBalances)
     setChild(res.data.childInfo)
      let balances = res.data.childBalances;
      let acum = 0;
      let acumR = 0;
      for(let item of balances){
        acum+= +item.contribution
        if(item.contributionType.includes('Rendimiento')){
          acumR = acum;
        }
      }
      setTotalContribution(acum)
      setRendimiento(acumR)
      if(!isAuth){
        navigate('/login')
      }
     
      })
  },[])
  return (
    <Row className={style.container} style = {{margin:"auto"}}>
      <Button style = {{width:"150px",height:"40px",backgroundColor: "#002D62", color: "#fff", marginLeft:"85%", marginBottom:"10px"}} variant="primary" onClick={e=>handleRegreat(e)}>
          Volver
        </Button>
      <h1 style = {{marginBottom:"7%",marginTop:"5%"}}>{`Estado de cuenta de ${child.name}`}</h1>
      <Row>
      <Col>
      <div>
      <span style = {{marginRight:"15px"}}>{"$"+child.pricePlan}</span>
      <Modal title = {"Actualizar plan"} titleB = {"editar"} isObjective = {false} getAmount = {getAmount} id = {id}/>
      </div>
      <span>Plan Mensual</span>
      </Col>
      <Col>
      <div>
      <span>{"$"+totalContribution}</span>
      </div>
      <span>Aportaciones totales</span>
      </Col>
      <Col>
      <div>
      <span>{"$"+rendimiento}</span>
      </div>
      <span>Rendimientos</span>
      </Col>
      <Col>
      <div>
      <span>{"$"+(totalContribution+rendimiento)}</span>
      </div>
      <span>Saldo actual</span>
      </Col>
      <Col>
      <div>
      <span style = {{marginRight:"15px"}}>{"$"+child.objetive}</span>
      <Modal title = {"Actualizar Objetivo"} titleB = {"editar"} isObjective = {true} getAmount = {getAmount} id = {id}/>
      </div>
      <span>Tu objetivo</span>
      </Col>
      </Row>
    <Row className = "mt-5">
      <span style = {{marginBottom:"5px"}}>Historial de aportaciones</span>
      <Col lg = {4}>
      <ReactHtmlTableToExcel
      id = {"pdf"}
      className = {style.button}
      table = {'tableBalance'}
      filename = {"Balance "+ child.name}
      sheet = {"Balance"}
      buttonText = {"CSV"}
      onClick = {(e)=>{console.log("entre")}}
      />
      <Button onClick = {(e)=>{generatePdf()}} className={style.button}>
        PDF
      </Button>
      </Col>
     
    </Row>
      <Col lg="10" style = {{marginLeft:"8%",  marginTop:"5%"}}>
         
        <DinamicTable headers={["Año", "Mes", "Aportacion", "Tipo de aportacion", "Comision", "Neto"]}
          title={""} showImg = {false} isB = {true}>
           {
              data.map((child, index) => 
              index>=init && index<=limit?<tr>
              <td>
               {child.createdAt.split("-")[0]}
              </td>
              <td>
               {child.createdAt.split("-")[1]}
              </td>
              <td>
             {"$"+child.contribution}
              </td>
              <td>
              {child.contributionType}
              </td>
              <td>
             {"$"+child.comission}
              
              </td>
              <td>
          {"$"+child.amount}
              </td>
              <td>
               
              </td>
            </tr>:null
           
              )
            }
             {
              data.map((child, index) => 
              index<init || (index>limit && index<data.length)?<tr>
              <td>
               { child.createdAt.split("-")[0]}
              </td>
              <td>
               { child.createdAt.split("-")[1]}
              </td>
              <td>
             {child.contribution}
              </td>
              <td>
              {child.contributionType}
              </td>
              <td>
             
              
              </td>
              <td>
          
              </td>
              <td>
               
              </td>
            </tr>:null
           
              )
            }
            
          
        </DinamicTable>
      </Col>
      <Col lg = {12} style = {{marginLeft:"35%"}}>
      <Pagination n = {(data.length/2)-1} handlePosition = {handlePosition}/>
      </Col>
    </Row>
  );
}

export default Container;