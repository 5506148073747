import React,{useEffect, useState} from 'react';
import style from './style.module.css'
import {Link} from 'react-router-dom';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {ReactComponent as Logo} from '../../utils/img/cometa_logo.svg'
function NavBar() {
  const [isLogged, setIsLogged] = useState(false);
  useEffect(() =>{
    let url = window.location.href;
    if(url.includes('profile')){
      setIsLogged(true);
    }else{
      setIsLogged(false);

    }
  },[]);
  return (
    <Navbar collapseOnSelect expand="lg" bg="red" variant="white" className={style.nav}>
      <Container>
        <Navbar.Brand href="/"><Logo/></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <>
             
       
          
            </>
          </Nav>
          <Nav className = {style.nav}>
          {!isLogged?<Link style={{ color: 'inherit', textDecoration: 'inherit'}} to ="/">Inicio</Link>:null}
          {!isLogged?<Link style={{ color: 'inherit', textDecoration: 'inherit'}} to ="/how-works">Como funciona</Link>:null}
            {!isLogged?<Link style={{ color: 'inherit', textDecoration: 'inherit'}} to ="/how-start">
              Como empezar
            </Link>:null}
           { !isLogged?<Link style={{ color: 'inherit', textDecoration: 'inherit'}} to ="/simulator">
              Simulador
            </Link>:null}
           { !isLogged?<Link style={{ color: 'inherit', textDecoration: 'inherit'}} to ="/login">
              Ingresar
            </Link>:null}
            {!isLogged?<Link style={{ color: 'inherit', textDecoration: 'inherit'}} to ="/register">
              Crear cuenta
            </Link>:null}
            {isLogged?<Link onClick = {e=>{sessionStorage.clear(); window.location.reload()}} style={{ color: 'inherit', textDecoration: 'inherit', paddingTop: '10px' }} to ="/login">
              Cerrar Sesion
            </Link>:null}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;