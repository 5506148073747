import React,{useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import NavBar from './components/NavBar';
import Home from './components/Home';
import Container from './components/SimulatorContainer';
import Results  from './components/Simulator/resultados';
import HowWorks from './components/HowWorks';
import HowStart from './components/HowStart';
import Login from './components/Login';
import Register from './components/Register';
import Profile from './components/Profile';
import Footer from './components/Footer';
import Balance from './components/ProfileComponents/Balances'
import {getUser} from './actions';
import {useDispatch} from 'react-redux';
function App() {


  const dispatch = useDispatch();
const Auth = useSelector(state=>state.authUser)
const [isAuth,setAuth] = useState(sessionStorage.getItem("isAuth"))
const [session, setSession] = useState(window.localStorage.getItem('isAuth'))
useEffect(()=>{
  let user = sessionStorage.getItem("userId");
  let stripeId = sessionStorage.getItem("stripeId");
  let haveDocuments = sessionStorage.getItem("haveDocuments");
  if(user && haveDocuments && stripeId)
  dispatch(getUser({userId: user, haveDocuments: haveDocuments, stripeId: stripeId, isAuth:sessionStorage.getItem("isAuth")}))
  setAuth(sessionStorage.getItem("isAuth"))
  console.log("entre")
  console.log(isAuth)
  console.log("aqui")
},[isAuth])
  return (
    <React.Fragment>      
    <Router>
    <Routes>
    <Route path="/" element={[<NavBar/>,<Home/>,<Footer/>]}/>
    <Route path="/simulator" element={[<NavBar/>,<Container/>,<Footer/>]}/>
    <Route path="/simulator/results" element={[<NavBar/>,<Results/>,<Footer/>]}/>
    <Route path="/how-works" element={[<NavBar/>,<HowWorks/>,<Footer/>]}/>
    <Route path="/how-start" element={[<NavBar/>,<HowStart/>,<Footer/>]}/>
    <Route path="/login" element={[<NavBar/>,<Login/>,<Footer/>]}/>
    <Route path="/register" element={[<NavBar/>,<Register/>,<Footer/>]}/>
    <Route path="/profile/documents/:id" element={isAuth||Auth?[<Profile wich = {"PlanAccount"}/>,<Footer/>]:<Navigate to = '/login'/>}/>
    <Route path="/profile/personal-info/:id" element={isAuth||Auth?[<Profile wich = {"Personal"}/>,<Footer/>]:<Navigate to = '/login'/>}/>
    <Route path="/profile/payments/:id" element={isAuth||Auth?[<Profile wich = {"Payments"}/>,<Footer/>]:<Navigate to = '/login'/>}/>
    <Route path="/profile/contracts/:id" element={isAuth||Auth?[<Profile wich = {"Contracts"}/>,<Footer/>]:<Navigate to = '/login'/>}/>
    <Route path="/profile/balance/:id" element={isAuth||Auth?[<Balance/>,<Footer/>]:<Navigate to = '/login'/>}/>
    {/* <Route path="/" element={}/> */}
    </Routes>
    </Router>
    </React.Fragment>
  );
}

export default App;
