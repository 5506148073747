import React,{useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import style from './style.module.css'
import { Button, Card, Form, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import MitadNino from "../../utils/img/ninosolo.png";
import { ReactComponent as Circulo } from "../../utils/img/circuloVerde.svg";
import {URL} from '../../actions/constants';
import Plan from '../Plan';
function Register() {
  const plan = useSelector(state=> state.plan)
  const [show, setShow] = useState(false)
  const [form, setForm] = useState({
    name:"",
    email:"",
    password:"",
    totalCostPlan:plan.totalCost, 
    recommendation:plan.recommendation, 
    totalContribution:plan.totalSaving
  })
  const navigate = useNavigate();
  const handleOnChange = (e)=>{
    e.preventDefault()
    if(e.target.id === "formBasicName"){
      setForm({...form,name:e.target.value})
    }else if(e.target.id === "formBasicPassword"){
      setForm({...form,password:e.target.value})
    }else{
      setForm({...form,email:e.target.value})
    }
    console.log(e.target.value)
  }
  const handleSubmit = (e)=>{
    e.preventDefault()
    axios.post(`${URL}create-account`,form)
    .then(res=>{
      if(res.data.status == 200){
        navigate(`/profile/documents/${res.data.id}`);
      }
    })
    console.log(form)
  }
  const handleClick = (e)=>{
    e.preventDefault()
    setShow(!show)
    navigate('/login');
  }
  const handleRedirect = (e) =>{
    e.preventDefault()
    navigate('/simulator');

  }
  return (
    <Row className = {style.container}>
      <Col lg = {4}>
      <Plan cBtn = {handleRedirect} title = {plan.type} btnText = {"Cambiar Plan"} totalCost = {plan.totalCost} recommendation = {plan.recommendation} totalSaving = {plan.totalSaving} recommendation2 = {plan.recommendation2} is = {true}/>
      </Col>
      <Col lg = {8}>
      {/* <Col lg = {1}>
     <img src = {MitadNino} className = {style.pos} />
     <div className = {style.posImg}>
     <Circulo/>
     </div>
     </Col> */}
     <Col lg = {11}>
    <Card className = {style.simulator}>
      <Card.Body>
        <Card.Title className = {style.titleF}>Crear cuenta</Card.Title>
        <Form onSubmit={e=>{handleSubmit(e)}}>
      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Control type="text" placeholder="Nombre y apellido" onChange={e=>{handleOnChange(e)}} value={form.name}
        style = {{width:"80%", margin:"auto"}}/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control type="text" placeholder="Correo electronico" onChange={e=>{handleOnChange(e)}} value={form.email}
        style = {{width:"80%", margin:"auto"}}/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Control type="text" placeholder="Indica cual sera tu contraseña" onChange={e=>{handleOnChange(e)}} value={form.password}
        style = {{width:"80%", margin:"auto"}}/>
        <Form.Text className="text-muted">
          <Button variant="primary" type="submit" style = {{marginLeft:"40%", marginTop:"20px"}}>
        Crear cuenta
      </Button>
      <Button variant="primary" onClick={e=>handleClick(e)} style = {{marginLeft:"40%", marginTop:"20px"}}>
       Iniciar sesion
      </Button>
      <br/>
      <p style = {{marginLeft:"12%", marginTop:"10px"}}>
      Al crear una cuenta protegemos tu informacion. No la compartiremos con nadie
      </p> 
        </Form.Text>
      </Form.Group>
    </Form>
    {show?<div className = {style.result}>
        <div className = {style.itemResult}>
          <span>Rendimiento anual estimado: </span> <span>6.5%</span>
        </div>
        <div className = {style.itemResult}>
          <span>Meses calculados: </span><span>96</span>
        </div>
        <div className = {style.itemResult}>
          <span>El monto estimado de tu fondo sería: </span><span>$540,000</span>
        </div>
      </div>:
      null
      }
      </Card.Body>
    </Card>
    </Col>
    </Col>
    </Row>
  );
}

export default Register;