import React,{useEffect, useState} from 'react';
import style from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Form, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import SideBar from '../SideBar';
import PlanAccount from "../ProfileComponents/PlanAccount";
import Payments from '../ProfileComponents/Payments';
import Contracts from '../ProfileComponents/Contracts';
import Personal from '../ProfileComponents/Personal';
import NavBar from '../NavBar';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ReactComponent as Circulo } from "../../utils/img/circuloVerde.svg";
import {URL} from '../../actions/constants';
function Profile({wich}) {
  const { id } = useParams();

  const [plan,setPlan] = useState({
    totalCost:"No disponible", 
    recommendation:"No disponible",
    totalSaving:"No disponible",
  })
  const [show, setShow] = useState(false)
  const [form, setForm] = useState({
    name:"",
    age:"",
    initialAmount:0,
    monthlyAmount:0
  })
  useEffect(()=>{
    axios.get(`${URL}client/plan/${id}`)
    .then(res=>{
      console.log(res.data);
      setPlan({
        totalCost:res.data.totalCost,
        recommendation:res.data.amountPlan,
        totalSaving:res.data.totalContribution,
      })
    })
  },[])
  return (
    <Row className = {style.container}>
      <Col lg = {3}>
      <SideBar/>
      </Col>
      <Col lg = {9}>
        <NavBar/>
        <div className = {style.containerProfile}>
        {
        wich === 'PlanAccount' && <PlanAccount totalCost={plan.totalCost} totalSaving = {plan.totalSaving} recommendation = {plan.recommendation}/>
        }
        {
        wich === 'Payments' && <Payments/>
        }
        {
        wich === 'Personal' && <Personal/>
        }
        {
        wich === 'Contracts' && <Contracts/>
        }</div>
      </Col>
    </Row>
  );
}

export default Profile;