import Pagination from 'react-bootstrap/Pagination';
import {useState, useEffect} from 'react';


const PaginationSimple = ({n, handlePosition})=>{
    let[ active, setActive ] = useState(1);
    let items = [];

    
for (let number = 1; number <= n; number++) {
  items.push(
    <Pagination.Item onClick = {e=>handlePosition(e)} id = {number} key={number} active={number === active}>
      {number}
    </Pagination.Item>,
  );
}

useEffect(()=>{

},[active]);
    return(
        <div >
    <Pagination  size="lg">{items}</Pagination>
  </div>
    )
};

export default PaginationSimple;