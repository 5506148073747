import {GET_RESULTS, SET_PLAN, USER_DATA} from './constants'
export function getResults(payload){
    return function(dispatch){
            dispatch({type: GET_RESULTS,payload})
    }
}
export function setPlan(payload){
    return function(dispatch){
            dispatch({type: SET_PLAN,payload})
    }
}
export function getUser(payload){
    return function(dispatch){
            dispatch({type: USER_DATA,payload})
    }
}