// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__AWOLX{\n    margin-top: 10px;\n}\n.style_containerProfile__X6iY9{\n    margin-top: 6%;\n  }\n@media (max-width: 1440px) {\n    .style_containerProfile__X6iY9{\n       margin-top: 3%;\n     }\n     \n   }", "",{"version":3,"sources":["webpack://./src/components/Profile/style.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,cAAc;EAChB;AACF;IACI;OACG,cAAc;KAChB;;GAEF","sourcesContent":[".container{\n    margin-top: 10px;\n}\n.containerProfile{\n    margin-top: 6%;\n  }\n@media (max-width: 1440px) {\n    .containerProfile{\n       margin-top: 3%;\n     }\n     \n   }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__AWOLX",
	"containerProfile": "style_containerProfile__X6iY9"
};
export default ___CSS_LOADER_EXPORT___;
