import React,{useState} from 'react';
import { Modal, Button, Container, Row, Col , Alert} from 'react-bootstrap';
import {} from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {URL} from '../../actions/constants';
const Moda = ({title, children, titleB, form, cleanForm}) => {
  const [isAuth,setAuth] = useState(sessionStorage.getItem("isAuth"))
    const [show, setShow] = useState(false);
  const navigate = useNavigate();
    const [alert,setAlert] = useState(false)
    const [error,setError] = useState(false)
    const stripeId = useSelector(state => state.stripeId)
    const customerId = useSelector(state => state.userId)
    const dispatch = useDispatch();
    const handleClose = (e) => {
      
        setShow(false)
        cleanForm()
    };
    const handleChild = (e)=>{
      e.preventDefault();
      
    }
    const handleSubmit = (e) => {
        axios.post(`${URL}client/save-card/${customerId}/${stripeId}`,form).then(res => {
            console.log(res)
            setShow(false)
            cleanForm()
           }).catch(err =>{
            setError(true)
           })
  };
    const handleShow = () => {
      if(titleB == "Agregar Menor"){
      
        navigate(`/profile/personal-info/${customerId}`);
              }else{
        cleanForm()
        
        setShow(true)}
    };
  useEffect (()=>{
    if(error){
        setTimeout(()=>{
            setError(false)
        },5000)
    }
},[error])
    return (
      <>
        <Button style = {{width:"150px",height:"40px",backgroundColor: "#002D62", color: "#fff", marginLeft:"85%", marginBottom:"10px"}} variant="primary" onClick={handleShow}>
          {titleB}
        </Button>
  
        <Modal  size="md" show={show} scrollable={true} onHide={handleClose} backdrop = 'static'>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
        <Container>
          {alert&&<Alert key={'warning'} variant={'warning'}>
      Los campos no pueden estar vacios!
    </Alert>}
    {error&&<Alert key={'danger'} variant={'danger'}>
      Ha ocurrido un error al guardar la tarjeta
    </Alert>}
          <Row>
            <Col xs={12} md={12}>
            {children}
            </Col>
          </Row>
          </Container>
        </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={e=>handleClose(e)}>
              Cerrar
            </Button>
            <Button style = {{backgroundColor: "#002D62"}} variant="primary" onClick={e=>handleSubmit(e)}>
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  export default Moda;