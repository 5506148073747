import style from "./style.module.css";
import { Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Simulator from "../Simulator";
import React, { useEffect, useState } from "react";
import plan1 from "../../utils/img/plan1.svg";
import {Modal, Button} from 'react-bootstrap';
import axios from 'axios';
import {URL} from '../../actions/constants';
function Plan({ cBtn, btnText,title, img,totalCost, recommendation, totalSaving, recommendation2,is, cb,id}) {
  const [email, setEmail] = useState({
    email:'', 
    totalCostForSending:totalCost, 
    recommendationForSending:recommendation, 
    totalContributionForSending:0,
    totalSavingForSending:totalSaving,
    savingMonthForSending:recommendation2,
    unitypeForSending:0
  });
  const [show, setShow] = useState(false);
  const [showM, setShowM] = useState(false);
  
  const handleClose = () => setShowM(false);
  const handleShow = () => setShowM(true);
  const handleEmail = (e)=>{
    setEmail({...email,email:e.target.value});
  }

  const handleSend = (e)=>{
    e.preventDefault();
    if(email.email == ''){
      setShowM(false);
      return;
    }
    axios.post(`${URL}send-plan`,email)
    .then((res)=>{
      setShowM(false);
    });
  }
  
  return (
    <div className={style.plan+" col s12 l3 plan checked-expand"} id = {id} onClick = {e=>cb(e)}>
      <div id = {id} className={style.circle} style = {{backgroundColor:is?"green":null}}/>
      <div id = {id} className="row no-vertical-margin">
        <div id = {id} className="option lighten-2 right ligthen-2 checked"></div>
      </div>
      <div id = {id} className={style.center+" row center"}>
        <img className="center" src={img||plan1} />
      </div>
      <div id = {id} className="row no-vertical-margin">
        <div className={style.center}>
          <span
            className="bolder light-blue-text text-cometa"
            style={{ marginLeft: "20px" }}
          >
          { 
          title=="none"?"Plan seleccionado":title
          }
          </span>
        </div>
      </div>
      <div className={style.purpleTextCometa+" row  bolder"}>
        <div className="col s6 left-align">Costo futuro:</div>
        <div className="col s6 right-align">{totalCost}</div>
      </div>
      <div className="row bolder">
        <div className={style.textBlue+" col s6  left-align"}>
          {" "}
          Ahorro mensual recomendado:
        </div>
        <div className={style.textBlue+" col s6 right-align"}>
          {recommendation}
        </div>
      </div>
      <div className={style.purpleTextCometa+" row bolder"}>
        <div className="col s6 left-align">Tu ahorro apróximado:</div>
        <div className="col s6 right-align">{totalSaving}</div>
      </div>
      <div className="row bolder">
        <div className={style.textBlue+" col s6 left-align"}>
          Ahorro mensual:
        </div>
        <div className="col s6 blue-text purpleTextCometa right-align">
          {recommendation2}
        </div>
      </div>
      <div className="row bolder">
        <div className={style.textBlue+" col s6 left-align"}>
          Meses calculados:
        </div>
        <div className={style.textBlue+" col s6 right-align"}>0</div>
      </div>
      {is?<div className={style.btnResultados+" row"}>
        <div className = 'col-lg-12 mt-4'>
        <button id = {title} onClick={e=>{cBtn(e)}}>{btnText}</button>
        </div>
        <div className = 'col-lg-12 mt-2'>
        <Button variant="primary" onClick={handleShow}>
        Enviar por correo
      </Button>

      <Modal show={showM} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enviar plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className = 'row bolder'>
          <label className={style.textBlue} style = {{marginBottom: '10px', fontSize:"20px"}}>Correo electronico</label>
          <input id = "email" onChange = {e=>handleEmail(e)} value = {email.email} required/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={e=>handleSend(e)}>
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>
       
        </div>
       
      </div>:null}
    </div>
  );
}

export default Plan;
