import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom';
import style from "./style.module.css";
import { Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as SP } from "../../utils/img/SPOTIFY.svg";
import { ReactComponent as FB } from "../../utils/img/FACEBOOK.svg";
import { ReactComponent as IT } from "../../utils/img/INSTAGRAM.svg";
import Nina from "../../utils/img/nina.png";
import Nino from "../../utils/img/nino.png";
import NinaPizzarra from "../../utils/img/ninaPizzarra.png";
import CirculoCochinito from "../../utils/img/circuloCochinito.png";
import CirculoNino from "../../utils/img/circuloNino.png";
import Linea from "../../utils/img/linea3.png";
import Simulator from '../Simulator';
function Home() {
  return (
    <>
      <Row className={"mt-5"}>
        <Col xs = {8} md = {8} lg = {8}>
          <div className={style.title}>
            <p>
              Conoce Cometa
            </p>
            <p className={style.subtitle}>
            Somos una asociación de padres, abuelos, tíos y familiares unidos a través de Fondo Educativo Cometa A.C. con el fin de crear un fondo de ahorro para la universidad de nuestros niños.
            <br/>
            <br/>
            Dos grandes instituciones financieras reguladas por la CNBV nos apoyaron para crear un fondo de ahorro educativo con todas las características que necesitábamos: 
            <br/>
            <br/>
            <ul>
            <li>1. Ahorro voluntario sin penalizaciones.</li>
            <li>2. Alternativas de inversión (Conservador, Moderado y Agresivo)</li>
            <li>3. Respaldo patrimonial, legal y financiero.</li>
            </ul>  
            </p>
            <br/>
            <Link style={{ color: 'inherit', textDecoration: 'inherit'}} to = "/register"><button>Comienza tu ahorro hoy</button></Link>
            
          </div>
        </Col>
        <Col>
          <Row className={style.containerImage}>
            <Col lg="12">
              <img src={NinaPizzarra} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className=" justify-content-center" style = {{marginTop:"100px"}}>
        <Col xs = {6} md = {6} lg = {6}>
          <Row className={style.containerImage}>
            <Col lg="12">
              <img src={CirculoCochinito} />
            </Col>
          </Row>
        </Col>
        <Col xs = {6} md = {6} lg = {6}>
          <div className={style.titleBlackF}>
            <p>
            El ahorro más importante de tu vida.
            </p>
            <p className={style.subtitle}>
            Fondo Cometa funciona como una alcancía, donde mes con mes guardas tu dinero, y lo mejor es que ¡Todos en tu familia pueden aportar para el futuro de tu niñ@!
            <br/>
            <br/>
            Además, tu dinero está seguro y respaldado por un fideicomiso, garantizando la entrega del ahorro.
            </p>
            <Link style={{ color: 'inherit', textDecoration: 'inherit'}} to = "/simulator"><button>Simula tu ahorro</button></Link>
          </div>
        </Col>
      </Row>

      <Row className={"mt-5"}>
        <Col xs = {8} md = {8} lg = {8}>
          <div className={style.title}>
            <p>
              Habla con un experto
            </p>
            <br/>
            <p className={style.titleBlackFS}>
           ¿Quieres abrir una cuenta?
            <br/>

            Ya tengo una cuenta.
            <br/>

            Tengo problemas con mi cuenta.
            </p>
            
          </div>
        </Col>
        <Col>
          <Row className={style.containerImage}>
            <Col lg="12">
              <img src={CirculoNino} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={"mt-5"}>
        <Col xs = {6} md = {6} lg = {6}>
          <div className={style.title}>
            <p>
              Preguntas frecuentes
            </p>
            <p className={style.smallFont}>
            ¿Tienes alguna duda? Resuélvela en nuestra sección de 
            <br/>
            preguntas y respuestas frecuentes
            </p>
            
          </div>
        </Col>
        <Col>
          <Row className={style.containerImage}>
            <Col lg="12">
              <ul>
                <li>¿Es una pregunta frecuente?</li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Home;
