
import style from './style.module.css'
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Simulator from '../Simulator';
import Coin from "../../utils/img/coin.png";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DinamicTable from '../DinamicTable'
import Carrusel from '../PlanContainer';
import {Link} from 'react-router-dom'
import { BsTrash } from 'react-icons/bs'; 
import {useSelector} from 'react-redux';
import Dialog from "../Dialog";
import DialogS from "../Dialog/Subscription";
import { useNavigate } from "react-router-dom";
import {URL} from '../../actions/constants';
import Modal from './Modal';
function Container() {
  const [isAuth,setAuth] = useState(sessionStorage.getItem("isAuth"))
  const [defaultCard, setDefaultCard] = useState(true)
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [url, setUrl] = useState([]);
  const userId = useSelector(state => state.userId);
  const handleDelete = (e)=>{
    e.preventDefault()
   

  }
  const handleRedirect = (e)=>{
    
    navigate(`/profile/balance/${e.target.id}`);

  }
  const handleClick = (id)=>{
   
    axios.get(`${URL}client/get-invitation-link/${id}`).then(res => {
       console.log(res.data)
       setUrl(res.data.link);
      })
  }
  const handleSubscription = (id)=>{
    axios.post(URL+'subscription/create-new-subscription', {id})
    .then(res => {
        console.log(res)

    })
    .catch(error => {
       console.log(error)
       
    });
  }
  useEffect(() =>{
      axios.get(`${URL}client/children/${userId}`).then(res => {
       console.log(res.data)
       setData(res.data.children)
      })
      if(!isAuth){
        navigate('/login')
      }
  },[])
  return (
    <Row className={style.container}>
      
      <Col lg="12">
        <DinamicTable headers={["Nombre", "Estado de cuenta", "Documentación","Suscripción","Activar Suscripción","Contrato","Acciones"]}
          title={"Saldos Y Contratos"} titleb={"Agregar Menor"}>
            {
              data.map((child, index) => 
              <tr>
              <td>
               { child.name + ' ' + child.lastName }
              </td>
              <td>
              <button className={style.button} href={child.contract} id = {child.id} onClick={e=>handleRedirect(e)}>balance</button>
              </td>
              <td>
              {child.isThereBCertificate ? 'Completa':'Incompleta'}
              </td>
              <td>
              {child.isSubscribe ? 'Activa':'Inactiva'}
              
              </td>
              <td>
              <DialogS  userId = {userId} id = {child.id} callback = {handleSubscription} subscribe = {child.isSubscribe ? false : true}/>
              </td>
              <td style = {{paddingTop:"13px"}}>
                {child.contract != null ? <a className={style.button} 
                style = {{padding:"10px", color:"white"}} href={child.contract} target="_blank">Ver</a>:'Aun no tiene contrato' }
              </td>
              <td>
                <Dialog url = {url} id = {child.id} callback = {handleClick}/>
              </td>
            </tr>
              )
            }
        </DinamicTable>
      </Col>
    </Row>
  );
}

export default Container;