import {Row, Col} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import {useState, useEffect} from "react";
import style from './style.module.css'
import Plan from '../Plan';
import plan1 from "../../utils/img/plan1.svg";
import plan2 from "../../utils/img/plan2.svg";
import plan3 from "../../utils/img/plan3.svg";
import {setPlan} from "../../actions";
function IndividualIntervalsExample({data, total}) {
  const plans = useSelector(state => state.plans);
  const plan = useSelector(state => state.plan);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selected, setSelected] = useState({
    bajo:false,
    medio:false,
    alto:false
  });
  const handleClick = (e) => {
    console.log(e.target.id);
    if(e.target.id == 2){
      setSelected({
        ...selected,
        bajo:false,
        medio:false,
        alto:true
      })
      setSelectedd({
        ...isSelected,
        bajo:false,
        medio:false,
        alto:true
      })
    }else if(e.target.id == 1){
      setSelected({
        ...selected,
        bajo:false,
        medio:true,
        alto:false
      })
      setSelectedd({
        ...isSelected,
        bajo:false,
        medio:true,
        alto:false
      })
    }else if(e.target.id == 0){
      setSelected({
        ...selected,
        bajo:true,
        medio:false,
        alto:false
      })
      setSelectedd({
        ...isSelected,
        bajo:true,
        medio:false,
        alto:false
      })
    }
    
  }
  const [isSelected, setSelectedd] = useState(
    {
      bajo:false,
      medio:false,
      alto:false
    }
  )
  const handleSelected = (e)=>{
    console.log(e.target.id)
    if(e.target.id == 2){
      setSelectedd({
        ...isSelected,
        bajo:false,
        medio:false,
        alto:true
      })
    }else if(e.target.id == 1){
      setSelectedd({
        ...isSelected,
        bajo:false,
        medio:true,
        alto:false
      })
    }else if(e.target.id == 0){
      setSelectedd({
        ...isSelected,
        bajo:true,
        medio:false,
        alto:false
      })
    }
   
  }
  const handleRedirect = (e) =>{
    e.preventDefault();
    dispatch(setPlan(data));
    console.log(e.target.id)
      let plan1 = plans.filter(plan => plan.type == e.target.id)
      dispatch(setPlan(plan1[0]));
      navigate('/register');

  }
  useEffect(()=>{
   
    if(plan.index == 0){
      setSelected({
        ...selected,
        bajo:true,
        medio:false,
        alto:false
      })
      setSelectedd({
        ...isSelected,
        bajo:true,
        medio:false,
        alto:false
      })
    }else if(plan.index == 1){
      setSelected({
        ...selected,
        bajo:false,
        medio:true,
        alto:false
      })
      setSelectedd({
        ...isSelected,
        bajo:false,
        medio:true,
        alto:false
      })
    }else if(plan.index == 2){
      setSelected({
        ...selected,
        bajo:false,
        medio:false,
        alto:true
      })
      setSelectedd({
        ...isSelected,
        bajo:false,
        medio:false,
        alto:true
      })
    }
    if(!plans.length > 0){
      navigate('/simulator');
    }
  },[plan])
  return (
    // <Carousel variant="dark" style = {{width:"450px", paddingLeft:"50px"}}>
    //   {
    //     plans.map((plan) => 
    //     <Carousel.Item interval={1500}>
    //    <Plan cBtn = {handleRedirect} btnText = {"Quiero este plan"} totalCost = {plan.totalCost} recommendation = {plan.recommendation} 
    //    totalSaving = {plan.totalSaving} recommendation2 = {plan.recommendation2}
    //    title = {plan.type} img = {plan["type"].includes("bajo")?plan1:plan["type"].includes("medio")?plan2:plan3}/>
    //   </Carousel.Item>
    //     )
    //   }
    // </Carousel>
    <Row style = {{justifyContent:"center", marginLeft:"8%", marginTop:"60px", marginBottom:"60px"}}>
    {
         plans.map((plan,index) => 
         <Col  onClick = {e=>handleSelected(e)} id = {index} lg = {4} className = {(index == 2 ? isSelected.alto?style.selected:null
         :index == 1 ? isSelected.medio?style.selected:null
        :index == 0 ? isSelected.bajo?style.selected:null:null )}>
         <Plan cBtn = {handleRedirect} btnText = {"Quiero este plan"} totalCost = {plan.totalCost} recommendation = {plan.recommendation} 
         totalSaving = {plan.totalSaving} recommendation2 = {plan.recommendation2}
         title = {plan.type} img = {plan["type"].includes("bajo")?plan1:plan["type"].includes("medio")?plan2:plan3} is = {(index == 2?selected.alto:index==1?selected.medio:selected.bajo)
        
        
         } cb = {handleClick} id = {index}/>
        </Col>
          )
        }
        </Row>
  );
}

export default IndividualIntervalsExample;