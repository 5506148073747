import React,{useState} from 'react';
import { Modal, Button, Container, Row, Col , Alert, Form} from 'react-bootstrap';
import {} from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import {URL} from '../../actions/constants';
const Moda = ({title, titleB, isObjective, getAmount, id}) => {
  const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [alert,setAlert] = useState(false)
    const [error,setError] = useState(false)
    const [amount,setAmount] = useState('')
    const handleClose = (e) => {
      
        setShow(false)
    };
    const handleChild = (e)=>{
      e.preventDefault();
      
    }
    const handleOnChange = (e)=>{
      setAmount(e.target.value);
    };
    const handleSubmit = (e) => {
      console.log("hola")
      if(isObjective){
        axios.put(`${URL}client/update-objetive/${id}`, {newamount: amount})
  .then(res=>{
    getAmount(amount, isObjective)
    setShow(false)
  })
      }else{
        axios.put(`${URL}client/update-plan/${id}`, {newamount: amount})
        .then(res=>{
          getAmount(amount, isObjective)
          setShow(false)
        })
      }
        
  };
    const handleShow = () => {
        
        setShow(true)
    };
  useEffect (()=>{
    if(error){
        setTimeout(()=>{
            setError(false)
        },5000)
    }
},[error])
    return (
      <>
        <Button style = {{backgroundColor: "#002D62", color: "#fff"}} variant="primary" onClick={handleShow}>
          {titleB}
        </Button>
  
        <Modal  size="md" show={show} scrollable={true} onHide={handleClose} backdrop = 'static'>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
        <Container>
          {alert&&<Alert key={'warning'} variant={'warning'}>
      Los campos no pueden estar vacios!
    </Alert>}
    {error&&<Alert key={'danger'} variant={'danger'}>
      Ha ocurrido un error al guardar la tarjeta
    </Alert>}
          <Row>
            <Col xs={12} md={12}>
            <Form.Group className="mb-3" controlId="childage">
        <Form.Label>Nuevo Monto</Form.Label>
        <Form.Control type="text" placeholder="Ingrese el nuevo monto" onChange = {e=>handleOnChange(e)} value = {amount}/>
      </Form.Group>
            </Col>
          </Row>
          </Container>
        </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={e=>handleClose(e)}>
              Cerrar
            </Button>
            <Button style = {{backgroundColor: "#002D62"}} variant="primary" onClick={e=>handleSubmit(e)}>
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  export default Moda;