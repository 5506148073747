import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as SP } from "../../utils/img/SPOTIFY.svg";
import { ReactComponent as FB } from "../../utils/img/FACEBOOK.svg";
import { ReactComponent as IT } from "../../utils/img/INSTAGRAM.svg";
import Nina from "../../utils/img/nina.png";
import Nino from "../../utils/img/nino.png";
import NinaCirculo from "../../utils/img/ninaCirculo.png";
import Pensar from "../../utils/img/pensar.png";
import Money from "../../utils/img/money.png";
import CirculoNino from "../../utils/img/circuloNino.png";
import Linea from "../../utils/img/linea3.png";
import Simulator from '../Simulator';
function Start() {
  return (
    <>
      <Row className={"mt-5"}>
        <Col xs = {8} md = {8} lg = {8}>
          <div className={style.title}>
            <p>
              ¿Como empezar?
            </p>
            <p className={style.subtitle}>
            Somos una asociación de padres, abuelos, tíos y familiares unidos a través de Fondo Educativo Cometa A.C. con el fin de crear un fondo de ahorro para la universidad de nuestros niños.
            <br/>
            <br/>
            Dos grandes instituciones financieras reguladas por la CNBV nos apoyaron para crear un fondo de ahorro educativo con todas las características que necesitábamos: 
            <br/>
            <br/>
            <ul>
            <li>1. Ahorro voluntario sin penalizaciones.</li>
            <li>2. Alternativas de inversión (Conservador, Moderado y Agresivo)</li>
            <li>3. Respaldo patrimonial, legal y financiero.</li>
            </ul>  
            </p>
            <br/>
            
            
          </div>
        </Col>
        <Col xs = {4} md = {4} lg = {4}>
          <Row className={style.containerImage}>
            <Col lg="12">
              <img src={NinaCirculo} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className=" justify-content-center" style = {{marginTop:"200px"}}>
        <Col lg="4">
          <Row className={style.containerImage}>
            <Col lg="12">
              <img src={Pensar} />
            </Col>
          </Row>
        </Col>
        <Col lg="4">
          <Row className={style.containerImage}>
            <Col lg="12">
              <img src={Money} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Start;
