import styled from 'styled-components';

export const Box = styled.div`
padding: 80px 60px;
background: var(--unnamed-color-7fbfd8) 0% 0% no-repeat padding-box;
background: #7FBFD8 0% 0% no-repeat padding-box;
opacity: 1;
position: fixed;
bottom: 0;
width: 100%;
max-height: 300px;
z-index: 10000;

@media (max-width: 100px) {
	padding: 70px 30px;
}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	/* background: red; */
`

export const Column = styled.div`
display: flex;
flex-direction: column;
text-align: left;
margin-left: 30px;
`;

export const Row = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill,
						minmax(220px, 1fr));
grid-gap: 20px;

@media (max-width: 1440px) {
	grid-template-columns: repeat(auto-fill,
						minmax(190px, 1fr));
}
`;

export const FooterLink = styled.a`
color: #fff;
margin-bottom: 20px;
font-size: 18px;
text-decoration: none;

&:hover {
	color: #EEA6A6;
	transition: 200ms ease-in;
}
`;
export const FooterLinkI = styled.a`
color: #fff;
margin-bottom: 20px;
font-size: 18px;
text-decoration: none;
`;
export const Icon = styled.a`
color: #fff;
margin-bottom: 20px;
font-size: 18px;
text-decoration: none;

&:hover {
	color: #EEA6A6;
	transition: 200ms ease-in;
}
`;
export const Heading = styled.p`
font-size: 24px;
color: #fff;
margin-bottom: 40px;
font-weight: bold;
`;
