import React,{useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import style from './style.module.css'
import { Button, Card, Form, Toast} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { responsivePropType } from 'react-bootstrap/esm/createUtilityClasses';
import {getResults} from '../../actions';
import {URL} from '../../actions/constants';
function Simulator() {
  
  const [show, setShow] = useState(false)
  const [form, setForm] = useState({
    childname:"",
    childage:"",
    firstContribution:"",
    mensualityContribution:""
  })
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOnChange = (e)=>{
    e.preventDefault()
    if(e.target.id === "childname"){
      setForm({...form,childname:e.target.value})
    }else if(e.target.id === "childage"){
      setForm({...form,childage:e.target.value})
    }else if(e.target.id === "firstContribution"){
      setForm({...form,firstContribution:e.target.value})
    }else{
      setForm({...form,mensualityContribution:e.target.value})
    }
    console.log(e.target.value)
  }
  const handleSubmit = (e)=>{
    e.preventDefault()
    axios.post(`${URL}results`,form)
    .then(res=>{
      console.log(res)
       if(res.data.status == 200){
        dispatch(getResults(res.data))
        console.log(res.data.results)
       navigate('/simulator/results');
       }
    })
  }
  const handleClick = (e)=>{
    e.preventDefault()
    setShow(!show)
    navigate('/login');
  }
  return (
    <Card className = {style.simulator}>
      <Card.Body>
        <Card.Title className = {style.titleF}>Calcular</Card.Title>
        <Card.Subtitle className = {style.subtitleF+" mb-2"} >Conoce cuanto necesitas ahorrar para la universidad de tus niños.</Card.Subtitle>
        <Form onSubmit={e=>handleSubmit(e)}>
      <Form.Group className="mb-3" controlId="childname">
        <Form.Label>Mi hijo(a) se llama.</Form.Label>
        <Form.Control type="text" placeholder="Nombre del beneficiario." onChange = {e=>handleOnChange(e)} value = {form.childname}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="childage">
        <Form.Label>Su edad es de.</Form.Label>
        <Form.Control type="text" placeholder="Edad del beneficiario." onChange = {e=>handleOnChange(e)} value = {form.childage}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="firstContribution">
        <Form.Control type="text" placeholder="Si ya cuentas con un ahorro para iniciar indica el monto." onChange = {e=>handleOnChange(e)} value = {form.firstContribution}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="mensualityContribution">
        <Form.Control type="text" placeholder=" Cuanto estimas que puedes ahorrar mensualmente para tu niño(a)." onChange = {e=>handleOnChange(e)} value = {form.mensualityContribution}/>
        <Form.Text className="text-muted">
          Minimo de 500 mxn.
          <Button variant="primary" type="submit" style = {{marginLeft:"85%"}}>
        Calcular
      </Button>
        </Form.Text>
      </Form.Group>
    </Form>
      </Card.Body>
    </Card>
  );
}

export default Simulator;